const config = {
    apiKey: process.env.GATSBY_FIREBASE_WEB_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
};

// let firebaseInstance: any;

export const getFirebase = (firebase: any, UID?: string) => {
    // if (firebaseInstance) {
    //     return firebaseInstance
    // }
    if (firebase.apps.length){
        return firebase.apps[0];
    }


    firebase.initializeApp(config)
    if (UID) {
        firebase.auth().signInWithCustomToken(UID).catch((error: any) => {
            console.warn('Unable to signin with custom token', error.message);
            console.info(`Tried ${UID}`)
        });
    }

    // firebaseInstance = firebase
    // console.log('Init firebase', {config}, firebaseInstance)
    if( process.env.GATSBY_FIRESTORE_EMULATOR === 'true') {
        // Should probably make these environment variables also
        const dbSettings = {
                host: "localhost:8080",
                ssl: false
        }
        console.log('Emulating firestore locally', {dbSettings})
        firebase.firestore().settings(dbSettings);
        firebase.functions().useFunctionsEmulator("http://localhost:5001");
    }

    return firebase
}