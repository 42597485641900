// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-customers-detail-tsx": () => import("./../src/pages/app/customers/detail.tsx" /* webpackChunkName: "component---src-pages-app-customers-detail-tsx" */),
  "component---src-pages-app-customers-index-tsx": () => import("./../src/pages/app/customers/index.tsx" /* webpackChunkName: "component---src-pages-app-customers-index-tsx" */),
  "component---src-pages-app-getting-started-tsx": () => import("./../src/pages/app/getting-started.tsx" /* webpackChunkName: "component---src-pages-app-getting-started-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-rewards-edit-tsx": () => import("./../src/pages/app/rewards/edit.tsx" /* webpackChunkName: "component---src-pages-app-rewards-edit-tsx" */),
  "component---src-pages-app-rewards-index-tsx": () => import("./../src/pages/app/rewards/index.tsx" /* webpackChunkName: "component---src-pages-app-rewards-index-tsx" */),
  "component---src-pages-app-rewards-new-tsx": () => import("./../src/pages/app/rewards/new.tsx" /* webpackChunkName: "component---src-pages-app-rewards-new-tsx" */),
  "component---src-pages-app-settings-index-tsx": () => import("./../src/pages/app/settings/index.tsx" /* webpackChunkName: "component---src-pages-app-settings-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-install-tsx": () => import("./../src/pages/install.tsx" /* webpackChunkName: "component---src-pages-install-tsx" */)
}

