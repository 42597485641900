import { useClientRouting } from '@shopify/app-bridge-react'
import { navigate } from 'gatsby';
export interface History {
    replace(path: string): void;
}

export interface Props {
    history: History;
}

// Use gatsby's navigation if coming from a embedded app
const Router = () => {
    const history = {
        replace: (path: string) => {
            console.log(`Custom nav ${path}`);
            navigate(path)
        }
    }
    useClientRouting(history)
    return null
}

export default Router