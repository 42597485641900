import React, { ReactNode, ReactChildren } from "react"
import { Link as InternalLink, navigate } from "gatsby"
import { Provider, Loading } from "@shopify/app-bridge-react"
import { AppProvider, Card, Page, SkeletonPage } from "@shopify/polaris"
// import { Nullable } from "../helpers/types"

// import GraphqlProvider from "../providers/graphql"
import { getFirebase } from "../helpers/firebase"
import FirebaseContext from "../providers/firebase"

import {
  getShopToken,
  getShopDomain,
  isAuthenticated,
  setHmacQueryCookie,
  getAuthUID,
  refreshAuth,
} from "../helpers/auth"

import "@shopify/polaris/styles.css"
import GraphqlProvider from "../providers/graphql"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import { AppProps } from "../helpers/app-props"
import enTranslations from '@shopify/polaris/locales/en.json';
import Router from "./Router"

const initialState: AppProps = {
  shop: undefined,
  token: undefined,
  isLoading: true,
}
type State = Readonly<typeof initialState>

const CustomLinkComponent = ({
  children,
  url,
  external,
  ...rest
}: {
  children?: ReactNode
  url: string
  external?: boolean
}) => {
  if (external) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    )
  }

  return (
    <InternalLink to={url} {...rest}>
      {children}
    </InternalLink>
  )
}
class AppLayout extends React.Component {
  readonly state: State = initialState

  constructor(props: {}) {
    super(props)

    // let shop = null
    let shop: State["shop"] = undefined

    if (typeof window !== "undefined") {
      shop = getShopDomain()
      // console.log({shop})

      if (!shop || shop === null || typeof shop == "undefined") {
        // console.log('Unable to read shop, redirecting to install');
        navigate(`/install`, {
          replace: true,
        })
      }
    }

    this.state = {
      shop,
      //   token: null,
      isLoading: true,
    }
  }

  componentDidMount = async () => {
    let isAuth = false
    const queryParams = window.location.search
    const { shop: shopDomain } = this.state

    if (!shopDomain) {
      return null;
    }

    if (queryParams && queryParams.includes("shop")) {
      setHmacQueryCookie(queryParams)
    }

    isAuth = await isAuthenticated()
    if (isAuth && shopDomain) {
      const token = getShopToken(shopDomain)
      const userToken = getAuthUID(shopDomain)

      if (userToken) {
        const app = import("firebase/app")
        const auth = import("firebase/auth")
        const database = import("firebase/firestore")
        Promise.all([app, auth, database]).then(values => {
          const firebase = getFirebase(values[0], userToken)
          this.setState({
            firebase,
            token,
            isLoading: false,
          })
        })
      } else {
        // show login button/screen -- redirect to reauth
        console.warn("no user token found")
      }
    } else {
        // console.log("User is not authenticated")
        // const refreshRequest = await refreshAuth(shopDomain);
        // console.log(refreshRequest)
        return refreshAuth(shopDomain, true)
    }
  }

  render() {
    const apiKey = process.env.GATSBY_SHOPIFY_APP_API_KEY
    // const { shop, token, isLoading, firebase } = this.state
    const { shop, token, isLoading, firebase } = this.state
    //let appTitle = '' // convert to new Gatsy useStaticQuery hook
    let content: ReactNode = ""

    if (typeof apiKey !== "string") {
      return <p>Missing API key</p>
    }

    if (!shop || shop === null || typeof shop == "undefined") {
      return <p>Redirecting...</p>
    }

    const config = {
      apiKey,
      shopOrigin: shop,
    }

    if (isLoading) {
      content = (
        <SkeletonPage title="" primaryAction secondaryActions={2}>
              <Loading />
        </SkeletonPage>
      )
    } else {
      if (!firebase || !shop) {
        content = 'Error';
      } else {
        content = (
          <FirebaseContext.Provider value={{ firebase, shop, token }}>
            <GraphqlProvider shop={shop} token={token}>
              {this.props.children as any}
            </GraphqlProvider>
          </FirebaseContext.Provider>
        )
      }
    }

    return (
      <Provider config={config}>
        <AppProvider
          linkComponent={CustomLinkComponent}
          forceRedirect={process.env.NODE_ENV === "development" ? false : true}
          // i18n={{
          //   Polaris: {
          //     Page: {
          //       Header: {
          //         rollupButton: "Actions",
          //       },
          //     },
          //   },
          // }}
          i18n={enTranslations}
        >
          {content}
          <Router />
        </AppProvider>
      </Provider>
    )
  }
}

export default AppLayout
