import React, { ReactNode } from "react"

import AppLayout from "./app"

interface IndexProps {
  pageContext?: any
  children?: ReactNode
}

const IndexLayout: React.FC<IndexProps> = (props) => {
  const { children, pageContext } = props
  if (pageContext.layout === "app") {
    return <AppLayout>{children}</AppLayout>
  }
  return <>{children}</>
}

export default IndexLayout
