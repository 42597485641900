import React from 'react'

const FirebaseContext = React.createContext(null)

export const withFirebase = (Component: any) => (props: any) => (
    <FirebaseContext.Consumer>
        {(options: {firebase: firebase.app.App, shop: string, token: string} | null) => {
            if (options) {
                const { firebase, shop, token } = options;
                return <Component {...props} firebase={firebase} shop={shop} token={token} />
            }
            return <Component {...props} />
        }}
    </FirebaseContext.Consumer>
)

export default FirebaseContext